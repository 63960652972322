<template>
  <date-range-picker
    ref="picker"
    v-bind:auto-apply="dateProps.autoApply"
    v-bind:always-show-calendars="dateProps.alwaysShowCalendars"
    v-bind:linked-calendars="dateProps.linkedCalendars"
    v-bind:locale-data="dateProps.localeData"
    v-bind:max-date="dateProps.maxDate"
    v-bind:opens="dateProps.opens"
    v-bind:ranges="rangesList"
    v-bind:show-dropdowns="dateProps.showDropdowns"
    v-on:update="updated"
    v-model="dateRange">
    <template v-slot:input="picker">
      <span>{{ humanReadable(picker.startDate, picker.endDate) }}</span>
    </template>
  </date-range-picker>
</template>

<script>
export default {
  components: {
    DateRangePicker: () => import('vue2-daterange-picker'),
  },
  props: {
    startDate: {
      required: true,
    },
    endDate: {
      required: true,
    },
    options: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      currentCompany: null,
      currentCampaign: null,
      currentAgent: null,
      dateRange: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
      dateProps: {
        alwaysShowCalendars: false,
        autoApply: true,
        linkedCalendars: true,
        localeData: {
          format: 'DD-MM-YYYY',
          applyLabel: this.$t('range-picker.apply'),
          cancelLabel: this.$t('range-picker.cancel'),
          customRangeLabel: this.$t('range-picker.custom'),
          daysOfWeek: this.$moment.localeData()
            .weekdaysMin(),
          monthNames: this.$moment.localeData()
            .monthsShort(),
          firstDay: 0,
        },
        maxDate: this.$moment()
          .toDate(),
        opens: 'center',
        showDropdowns: true,
        ...this.options,
      },
      ranges: [
        {
          name: 'range-picker.today',
          from: this.$moment()
            .toDate(),
          to: this.$moment()
            .toDate(),
        },
        {
          name: 'range-picker.yesterday',
          from: this.$moment()
            .subtract(1, 'days')
            .toDate(),
          to: this.$moment()
            .subtract(1, 'days')
            .toDate(),
        },
        {
          name: 'range-picker.last-7-days',
          from: this.$moment()
            .subtract(7, 'days')
            .toDate(),
          to: this.$moment()
            .subtract(1, 'days')
            .toDate(),
        },
        {
          name: 'range-picker.last-28-days',
          from: this.$moment()
            .subtract(28, 'days')
            .toDate(),
          to: this.$moment()
            .subtract(1, 'days')
            .toDate(),
        },
        {
          name: 'range-picker.last-month',
          from: this.$moment()
            .subtract(1, 'month')
            .startOf('month')
            .toDate(),
          to: this.$moment()
            .subtract(1, 'month')
            .endOf('month')
            .toDate(),
        },
      ],
    };
  },
  computed: {
    rangesList() {
      const ranges = {};

      this.ranges.forEach((range) => {
        ranges[this.$t(range.name)] = [range.from, range.to];
      });

      return ranges;
    },
  },
  methods: {
    findSelectedRange(start, end) {
      return this.ranges.find((range) => {
        const from = this.$moment(range.from);
        const to = this.$moment(range.to);

        return start.isSame(from, 'day') && end.isSame(to, 'day');
      });
    },
    humanReadable(from, to) {
      const dtFrom = this.$moment(from);
      const dtTo = this.$moment(to);

      if (dtFrom.isValid() && dtTo.isValid()) {
        const range = this.findSelectedRange(dtFrom, dtTo);

        if (range !== undefined) {
          return this.$t(range.name);
        }

        return `${dtFrom.format('D MMM YYYY')} - ${dtTo.format('D MMM YYYY')}`;
      }

      return this.$t('range-picker.unset');
    },
    updated(dates) {
      this.$emit('update', dates.startDate, dates.endDate);
    },
    reset() {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
    },
  },
};
</script>
